import BreadCrumb from "../../components/Common/Breadcrumb"
import OpenAIComponent from "../../components/Cleverest/OpenAIComponent"
import React, { useEffect, useRef, useState } from "react"

import { oFalacias } from "../../constants/falacias"
import { getOpenAIResponse } from "../../openaiService"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"

// emoji
import EmojiPicker from "emoji-picker-react"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar5 from "../../assets/images/users/avatar-5.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"

// simple bar
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"

import { handleSearchData } from "components/Common/searchFile"

const Falacias = () => {
  // Creamos una referencia para el input
  const inputRef = useRef(null)

  const chats = [
    {
      id: 1,
      roomId: 1,
      status: "intermediate",
      image: avatar2,
      name: "Steven Franklin",
      description: "Hey! there I'm available",
      time: "05 min",
    },
    {
      id: 2,
      roomId: 2,
      status: "online",
      image: avatar3,
      name: "Adam Miller",
      description: "I've finished it! See you so",
      time: "12 min",
    },
    {
      id: 3,
      roomId: 3,
      status: "online",
      name: "Keith Gonzales",
      description: "This theme is awesome!",
      time: "24 min",
      isImg: true,
      profile: "K",
    },
    {
      id: 4,
      roomId: 4,
      status: "intermediate",
      image: avatar4,
      name: "Jose Vickery",
      description: "Nice to meet you",
      time: "1 hr",
    },
    {
      id: 5,
      roomId: 5,
      status: "offline",
      name: "Mitchel Givens",
      description: "Hey! there I'm available",
      time: "3 hrs",
      isImg: true,
      profile: "M",
    },
    {
      id: 6,
      roomId: 6,
      status: "online",
      image: avatar6,
      name: "Stephen Hadley",
      description: "I've finished it! See you so",
      time: "5 hrs",
    },
    {
      id: 7,
      roomId: 7,
      status: "online",
      name: "Keith Gonzales",
      description: "This theme is awesome!",
      time: "24 min",
      isImg: true,
      profile: "K",
    },
  ]

  let chatMensajes = [
    // { id: 1, role: "user", msg: "Good morning 😊", time: "10:00" },
    // { id: 2, role: "assistant", msg: "Hi, How are you? What about our next meeting?", time: "10:02" },
  ]

  // Al cargar el componente, usamos useEffect para poner el foco
  useEffect(() => {
    // Si el inputRef existe, le asignamos el foco
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [chatMensajes])

  // const messages = [
  //   {
  //     id: 1,
  //     roomId: 1,
  //     sender: "Steven Franklin",
  //     message: "Hello!",
  //     usermessages: [
  //       {
  //         id: 1,
  //         to_id: 1,
  //         msg: "Good morning 😊",
  //         time: "10:00",
  //         isImages: false,
  //       },
  //       {
  //         id: 2,
  //         to_id: 2,
  //         msg: "Hi, How are you? What about our next meeting?",
  //         isImages: false,
  //         time: "10:02",
  //       },
  //       {
  //         id: 3,
  //         to_id: 1,
  //         msg: "Yeah everything is fine",
  //         isImages: false,
  //         time: "10:06",
  //       },
  //       {
  //         id: 4,
  //         to_id: 2,
  //         msg: "& Next meeting tomorrow 10.00AM",
  //         isImages: false,
  //         time: "10:06",
  //       },
  //       {
  //         id: 5,
  //         to_id: 1,
  //         msg: "Wow that's great",
  //         isImages: false,
  //         time: "10:07",
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     roomId: 2,
  //     sender: "Adam Miller",
  //     message: "Hello!",
  //     usermessages: [
  //       {
  //         id: 6,
  //         to_id: 1,
  //         msg: "Hi, How are you? What about our next meeting?",
  //         time: "10:02",
  //       },
  //       { id: 7, to_id: 2, msg: "Yeah everything is fine", time: "10:06" },
  //       {
  //         id: 8,
  //         to_id: 1,
  //         msg: "& Next meeting tomorrow 10.00AM",
  //         time: "10:06",
  //       },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     roomId: 3,
  //     sender: "Keith Gonzales",
  //     message: "Hello!",
  //     time: "11:02",
  //     usermessages: [
  //       { id: 1, to_id: 1, msg: "Yeah everything is fine", time: "10:06" },
  //       {
  //         id: 2,
  //         to_id: 2,
  //         msg: "& Next meeting tomorrow 10.00AM",
  //         time: "10:06",
  //       },
  //       { id: 3, to_id: 1, msg: "Wow that's great", time: "10:07" },
  //     ],
  //   },

  //   {
  //     id: 4,
  //     roomId: 4,
  //     sender: "Jose Vickery",
  //     message: "Hello!",
  //     time: "1 hr",
  //     usermessages: [
  //       { id: 1, to_id: 1, msg: "Good morning 😊", time: "10:00" },
  //       { id: 2, to_id: 2, msg: "Yeah everything is fine", time: "10:06" },
  //     ],
  //   },
  //   {
  //     id: 5,
  //     roomId: 5,
  //     sender: "Mitchel Givens",
  //     message: "Hello!",
  //     time: "11:05",
  //     usermessages: [
  //       { id: 1, to_id: 1, msg: "Good morning 😊", time: "10:00" },
  //       {
  //         id: 2,
  //         to_id: 2,
  //         msg: "& Next meeting tomorrow 10.00AM",
  //         time: "10:06",
  //       },
  //       { id: 3, to_id: 1, msg: "Wow that's great", time: "10:07" },
  //     ],
  //   },
  //   {
  //     id: 6,
  //     roomId: 6,
  //     sender: "Stephen Hadley",
  //     message: "Hello!",
  //     time: "1 hr",
  //     usermessages: [
  //       { id: 1, to_id: 1, msg: "Good morning 😊", time: "10:00" },
  //       {
  //         id: 2,
  //         to_id: 2,
  //         msg: "Hi, How are you? What about our next meeting?",
  //         time: "10:02",
  //       },
  //       { id: 3, to_id: 1, msg: "Yeah everything is fine", time: "10:06" },
  //       {
  //         id: 4,
  //         to_id: 2,
  //         msg: "& Next meeting tomorrow 10.00AM",
  //         time: "10:06",
  //       },
  //     ],
  //   },
  //   {
  //     id: 7,
  //     roomId: 7,
  //     sender: "Keith Gonzales",
  //     message: "hyyy",
  //     time: "1 hr",
  //     usermessages: [
  //       { id: 1, to_id: 1, msg: "Good morning 😊", time: "10:00" },
  //       {
  //         id: 2,
  //         to_id: 2,
  //         msg: "Hi, How are you? What about our next meeting?",
  //         time: "10:02",
  //       },
  //       { id: 3, to_id: 1, msg: "Yeah everything is fine", time: "10:06" },
  //       {
  //         id: 4,
  //         to_id: 2,
  //         msg: "& Next meeting tomorrow 10.00AM",
  //         time: "10:06",
  //       },
  //       { id: 5, to_id: 1, msg: "Wow that's great", time: "10:07" },
  //     ],
  //   },
  // ]

  document.title = "Falacias | CampusVirtual"

  const [falacia, setFalacia] = useState("")
  const [input, setInput] = useState("")
  const [response, setResponse] = useState("")
  const [messagesData, setMessagesData] = useState()
  const [curMessage, setCurMessage] = useState("")
  const [isdisable, setDisable] = useState(false)
  const [emoji, setEmoji] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [chatMessages, setChatMessages] = useState(chatMensajes)

  const [copyMsgAlert, setCopyMsgAlert] = useState(false)
  const copyMsg = ele => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML
    navigator.clipboard.writeText(copyText)
    setCopyMsgAlert(true)
    if (copyText) {
      setTimeout(() => {
        setCopyMsgAlert(false)
      }, 1000)
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    // Elegir una falacia al azar
    const randomIndex = Math.floor(Math.random() * oFalacias.length)
    const randomFalacia = oFalacias[randomIndex]
    setFalacia(randomFalacia)
    //setMessagesData(messages)

    setDisable(true)
  }, [])

  //scroll simple bar
  const scrollRef = useRef(null)
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.getScrollElement().scrollTop =
        scrollRef.current.getScrollElement().scrollHeight
    }
  })

  // emoji
  const [emojiArray, setEmojiArray] = useState("")
  const onEmojiClick = (event, emojiObject) => {
    setEmojiArray([...emojiArray, emojiObject.emoji])
    setCurMessage(curMessage + event.emoji)
    setDisable(true)
  }

  //  img upload
  const handleImageChange = event => {
    event.preventDefault()
    let reader = new FileReader()
    let file = event.target.files[0]
    reader.onloadend = () => {
      setSelectedImage(reader.result)
      setDisable(true)
    }
    reader.readAsDataURL(file)
  }

  // useEffect(() => {
  //   const a = (messages || []).find(i => i.id)
  //   const a1 = a?.usermessages[a?.usermessages.length - 2]
  //   const a2 = a?.usermessages[a?.usermessages.length - 1]
  //   if (a2?.isSameTime) {
  //     setMessagesData(
  //       (messages || []).map(item => {
  //         const updateMessage = item.usermessages.filter(data =>
  //           a2.time === a1.time
  //             ? {
  //                 ...data,
  //                 id: a1.id,
  //                 to_id: data.to_id,
  //                 msg: data.msg,
  //                 isSameTime: a1.time === a2.time,
  //                 images: data.images,
  //                 time: (a1.time = 0),
  //               }
  //             : { ...item }
  //         )
  //         return { ...item, usermessages: updateMessage }
  //       })
  //     )
  //   } else {
  //     setMessagesData(messages)
  //   }
  //   //console.log("useEffect:: messages.length: " + messages.length)
  // }, [messages])

  const handleSubmit = async e => {
    //e.preventDefault()
    console.log("oFalacias.length: " + oFalacias.length)
    const randomIndex = Math.floor(Math.random() * oFalacias.length)
    const randomFalacia = oFalacias[randomIndex]
    setFalacia(randomFalacia)
    setDisable(true)
    setChatMessages([])
  }

  const FalaciasProperties = createSelector(
    state => state.falacias,
    Falacias => ({
      //falacias: Falacias.chats,
      //groups: Falacias.groups,
      //contacts: Falacias.contacts,
      //messages: Falacias.messages,
      //loading: Falacias.loading
    })
  )

  const onAddMessagesd = e => {}

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setCurMessage(value)
      //setDisable(true)
      addMessage()
    }
  }

  const { falacias, groups, contacts, loading } =
    useSelector(FalaciasProperties)

  const [isLoading, setLoading] = useState(false)

  const [activeTab, setactiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const currentUser = {
    name: "Jimena Casillas",
    isActive: true,
  }
  const [menu1, setMenu1] = useState(false)

  const currentTime = new Date()
  const hours = currentTime.getHours()
  const minutes = currentTime.getMinutes()
  const time = `${hours}: ${minutes}`

  const addMessage = async () => {
    setDisable(true)
    if (curMessage !== "" || selectedImage !== null) {
      console.log("chatMessages.length: " + chatMessages.length)
      let newMessage = {
        id: Math.floor(Math.random() * 100),
        role: "user",
        msg: curMessage,
        isSameTime: true,
        images: selectedImage,
        time: time,
      }
      //onAddMessage(newMessage);
      chatMessages.push(newMessage)
      setChatMessages(chatMessages)
      console.log("chatMessages.length: " + chatMessages.length)
      setCurMessage("")
      setEmoji(false)
      setSelectedImage(null)
      setLoading(true)
      const aiResponse = await getOpenAIResponse(
        falacia.ejemplo,
        curMessage,
        falacia.explicacion,
        falacia.consejo_para_detectarla
      )
      setResponse(aiResponse.choices[0].message.content)
      newMessage = {
        id: Math.floor(Math.random() * 100),
        role: "assistant",
        msg: aiResponse.choices[0].message.content,
        isSameTime: true,
        time: time,
      }
      chatMessages.push(newMessage)
      setChatMessages(chatMessages)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="CampusVirtual" breadcrumbItem="Falacias" />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div>
                    <div className="py-4 border-bottom">
                      <div className="d-flex">
                        <div className="align-self-center me-3">
                          <img
                            src={avatar1}
                            className="avatar-xs rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-15 mt-0 mb-1">
                            {currentUser.name}
                          </h5>
                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle me-2" />
                            Active
                          </p>
                        </div>

                        <div>
                          <Dropdown
                            isOpen={menu1}
                            toggle={() => setMenu1(!menu1)}
                            className="chat-noti-dropdown active"
                          >
                            <DropdownToggle
                              tag="a"
                              className="btn dropdown-toggle"
                            >
                              <i className="bx bx-bell bx-tada"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem href="#">Action</DropdownItem>
                              <DropdownItem href="#">
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#">
                                Something else
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav position-relative">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Ejercicio</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            <i className="bx bx-group font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">
                              Explicación
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleTab("3")
                            }}
                          >
                            <i className="bx bx-book-content font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Consejo</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-3">
                        <TabPane tabId="1">
                          <div>
                            <Card>
                              <CardHeader className="bg-transparent border-bottom text-uppercase">
                                ¿Falacia? Explica tus razones
                              </CardHeader>
                              <CardBody>
                                <blockquote className="card-blockquote mb-0">
                                  <CardText>{falacia.ejemplo}</CardText>
                                </blockquote>
                              </CardBody>
                            </Card>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                onClick={handleSubmit}
                              >
                                Nuevo Ejercicio
                              </button>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <Card>
                              <CardHeader className="bg-transparent border-bottom text-uppercase">
                                Explicación
                              </CardHeader>
                              <CardBody>
                                <blockquote className="card-blockquote mb-0">
                                  <CardText>{falacia.explicación}</CardText>
                                </blockquote>
                              </CardBody>
                            </Card>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div>
                            <Card>
                              <CardHeader className="bg-transparent border-bottom text-uppercase">
                                Consejo para detectarla
                              </CardHeader>
                              <CardBody>
                                <blockquote className="card-blockquote mb-0">
                                  <CardText>
                                    {falacia.consejo_para_detectarla}
                                  </CardText>
                                </blockquote>
                              </CardBody>
                            </Card>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>

                <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="4" xs="9">
                          <h5 className="font-size-15 mb-1">Socrates BOT</h5>

                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle me-2" />
                            Active now
                          </p>
                        </Col>
                        <Col md="8" xs="3">
                          <ul className="list-inline user-chat-nav text-end mb-0">
                            <li className="list-inline-item d-none d-sm-inline-block">
                              <Dropdown className="me-1">
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-search-alt-2" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-md">
                                  <Form className="p-3">
                                    <FormGroup className="m-0">
                                      <InputGroup>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="searchMessage"
                                          placeholder="Search ..."
                                          aria-label="Recipient's username"
                                        />
                                        {/* <InputGroupAddon addonType="append"> */}
                                        <Button color="primary" type="submit">
                                          <i className="mdi mdi-magnify" />
                                        </Button>
                                        {/* </InputGroupAddon> */}
                                      </InputGroup>
                                    </FormGroup>
                                  </Form>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="list-inline-item d-none d-sm-inline-block">
                              <Dropdown className="me-1">
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-cog" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="#">
                                    View Profile
                                  </DropdownItem>
                                  <DropdownItem href="#">
                                    Clear chat
                                  </DropdownItem>
                                  <DropdownItem href="#">Muted</DropdownItem>
                                  <DropdownItem href="#">Delete</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="list-inline-item">
                              <Dropdown>
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-dots-horizontal-rounded" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem href="#">Action</DropdownItem>
                                  <DropdownItem href="#">
                                    Another Action
                                  </DropdownItem>
                                  <DropdownItem href="#">
                                    Something else
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <div className="chat-conversation p-3">
                        <SimpleBar style={{ height: "286px" }}>
                          {isLoading ? (
                            <Spinners setLoading={setLoading} />
                          ) : (
                            <ul
                              className="list-unstyled mb-0"
                              id="users-conversation"
                            >
                              {chatMessages &&
                                (chatMessages || []).map(message => {
                                  return (
                                    <li
                                      key={message.id}
                                      className={
                                        message.role === "user" ? "right" : ""
                                      }
                                    >
                                      <div className="conversation-list">
                                        <div className="ctext-wrap">
                                          <div className="conversation-name">
                                            {message.role === "user"
                                              ? "You"
                                              : "Sócrates"}
                                          </div>
                                          <p>{message.msg}</p>
                                          <p className="chat-time mb-0">
                                            <i className="bx bx-time-five align-middle me-1"></i>
                                            {message.time}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                })}
                            </ul>
                          )}
                        </SimpleBar>
                      </div>
                      {selectedImage && (
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                          <div className="flex-grow-1">
                            <img
                              src={selectedImage}
                              alt="select img"
                              style={{ width: "150px", height: "auto" }}
                            />
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => setSelectedImage(null)}
                            >
                              <i className="bx bx-x align-middle"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {copyMsgAlert && (
                        <UncontrolledAlert color="warning" role="alert">
                          {" "}
                          Message copied
                        </UncontrolledAlert>
                      )}
                      {emoji && (
                        <EmojiPicker
                          onEmojiClick={onEmojiClick}
                          width={250}
                          height={382}
                        />
                      )}

                      <div className="p-3 chat-input-section">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <input
                                ref={inputRef}
                                type="text"
                                value={curMessage}
                                onKeyPress={onKeyPress}
                                onChange={e => {
                                  setCurMessage(e.target.value)
                                  setDisable(false)
                                }}
                                className="form-control chat-input"
                                placeholder="Escribe aqui..."
                              />
                              <div className="chat-input-links">
                                <ul className="list-inline mb-0">
                                  <li
                                    className="list-inline-item"
                                    onClick={() => setEmoji(!emoji)}
                                  >
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-emoticon-happy-outline me-1"
                                        id="Emojitooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Emojitooltip"
                                      >
                                        Emojis
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <label
                                      htmlFor="imageInput"
                                      style={{ color: "#556ee6", fontSize: 16 }}
                                    >
                                      <i
                                        className="mdi mdi-file-image-outline me-1"
                                        id="Imagetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Imagetooltip"
                                      >
                                        Images
                                      </UncontrolledTooltip>
                                    </label>
                                    <input
                                      type="file"
                                      id="imageInput"
                                      className="d-none"
                                      onChange={handleImageChange}
                                    />
                                  </li>
                                  <li
                                    className="list-inline-item"
                                    onClick={() => {}}
                                  >
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-file-document-outline"
                                        id="Filetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Filetooltip"
                                      >
                                        Add Files
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              disabled={isdisable}
                              onClick={() => addMessage()}
                              className="btn btn-primary btn-rounded chat-send w-md "
                            >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>{" "}
                              <i className="mdi mdi-send" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Falacias
