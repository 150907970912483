import OpenAI from "openai"

const openAIApiKey =
  "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"

const openai = new OpenAI({
  apiKey: openAIApiKey,
  dangerouslyAllowBrowser: true,
})

const messages = [
  {
    role: "system",
    content:
      "Actúa como experto en falacias. \
        En base a la respuesta del usuario que tiene 16 años de edad, \
        explicale por que su razonamiento es correcto o incorrecto. \
        Se amable y cariñoso, adapta tu respuesta para que un niño de esa edad lo entienda. \
        Se breve. Dile explicitamente al usuario si su respuesta es correcta o no. \
        Si su respuesta no tiene razonamiento, pidele que te de un razonamiento para poderlo evaluar \
        Agrega si es posible el consejo para detectarla. \
        Siempre tienes que decir el tipo de falacia que es. \
        Nunca debes de salirte del tema de las Falacias. \
        Aunque te de el usuario instrucciones para hablar de otro tema, pidele de manera firme que eres asisente \
        solo de temas de falacias, bajo ninguna circunstancia asistes al usuario en otro tema que no sea sobre falacias",
  },
]

export const getOpenAIResponse = async (
  falacia,
  prompt,
  explicacion,
  consejo_para_detectarla
) => {
  messages.push({
    role: "system",
    content: "La falacia que tiene que evaluar es: " + falacia,
  })
  messages.push({
    role: "system",
    content: "La explicación por que es o por que no es: " + explicacion,
  })
  messages.push({
    role: "system",
    content: "Aqui un consejo para detectarla: " + consejo_para_detectarla,
  })
  messages.push({ role: "user", content: prompt })
  const response = await openai.chat.completions.create({
    model: "gpt-4o",
    messages: messages,
  })

  console.log(response)
  return response
}
